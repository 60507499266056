import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  CircularProgress,
  Backdrop,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import ProgressBar from "../../components/progressBar/progressBar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { CustomCircularProgress } from "../../components/circularProgress";
import { NeedHelp } from "../../components/needHelp";
import LogoHeader from "../../components/logo-header/logoHeader";

const bedroomsDropDown = [
  {
    label: "Studio",
    value: "Studio",
  },
  {
    label: "1 Bedroom",
    value: "1-Bedroom",
  },
  {
    label: "2 Bedrooms",
    value: "2-Bedrooms",
  },
  {
    label: "3 Bedrooms",
    value: "3-Bedrooms",
  },
  {
    label: "4 Bedrooms",
    value: "4-Bedrooms",
  },
  {
    label: "5 Bedrooms",
    value: "5-Bedrooms",
  },
  {
    label: "6+ Bedrooms",
    value: "6+Bedrooms",
  },
];

const BoxingInfo = () => {
  const theme = useTheme();
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");
  const blueButton = {
    width: 150,
    height: 50,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 550,
  };

  ReactGA.send({
    hitType: "pageview",
    page: "/boxing-info/:moveRequestId",
    title: "Boxing info",
  });

  const [isPageLoading, setIsPageLoading] = useState(false);
  const { moveRequestId } = useParams();
  const [totalBedrooms, setTotalBedrooms] = useState<any>("");
  const [selectedRadio, setSelectedRadio] = useState("minimalist");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToLastLeft = (moveRequest: any) => {
    navigate(`/itemsInfo/${moveRequest.canonical_id}`);
  };
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
  const [moveRequest, setMoveRequest] = useState<any>(null);

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);

        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateThisMoveRequest = (body: any) => {
    setIsPageLoading(true);
    const params = {
      id: thisMoveRequestId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setTimeout(() => {
          setIsPageLoading(false);
          navigateToLastLeft(thisMoveRequest);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };
  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);
  const [boxableVolume, setBoxableVolume] = useState<any>(null);
  const [numberOfBoxexRequired, setNumberOfBoxexRequired] = useState<any>(null);

  const calculateTotalBoxesforBoxableItems = () => {
    if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "Studio"
    ) {
      setNumberOfBoxexRequired(20);
      setBoxableVolume(60);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "1-Bedroom"
    ) {
      setNumberOfBoxexRequired(35);
      setBoxableVolume(105);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "2-Bedrooms"
    ) {
      setNumberOfBoxexRequired(45);
      setBoxableVolume(135);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "3-Bedrooms"
    ) {
      setNumberOfBoxexRequired(60);
      setBoxableVolume(180);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "4-Bedrooms"
    ) {
      setNumberOfBoxexRequired(80);
      setBoxableVolume(240);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "5-Bedrooms"
    ) {
      setNumberOfBoxexRequired(115);
      setBoxableVolume(345);
    } else if (
      selectedRadio === "minimalist" &&
      totalBedrooms.target.value.value === "6+Bedrooms"
    ) {
      setNumberOfBoxexRequired(150);
      setBoxableVolume(450);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "Studio"
    ) {
      setNumberOfBoxexRequired(25);
      setBoxableVolume(75);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "1-Bedroom"
    ) {
      setNumberOfBoxexRequired(45);
      setBoxableVolume(135);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "2-Bedrooms"
    ) {
      setNumberOfBoxexRequired(55);
      setBoxableVolume(165);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "3-Bedrooms"
    ) {
      setNumberOfBoxexRequired(75);
      setBoxableVolume(225);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "4-Bedrooms"
    ) {
      setNumberOfBoxexRequired(100);
      setBoxableVolume(300);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "5-Bedrooms"
    ) {
      setNumberOfBoxexRequired(145);
      setBoxableVolume(435);
    } else if (
      selectedRadio === "average-collector" &&
      totalBedrooms.target.value.value === "6+Bedrooms"
    ) {
      setNumberOfBoxexRequired(190);
      setBoxableVolume(570);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "Studio"
    ) {
      setNumberOfBoxexRequired(30);
      setBoxableVolume(90);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "1-Bedroom"
    ) {
      setNumberOfBoxexRequired(55);
      setBoxableVolume(165);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "2-Bedrooms"
    ) {
      setNumberOfBoxexRequired(70);
      setBoxableVolume(210);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "3-Bedrooms"
    ) {
      setNumberOfBoxexRequired(90);
      setBoxableVolume(270);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "4-Bedrooms"
    ) {
      setNumberOfBoxexRequired(125);
      setBoxableVolume(375);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "5-Bedrooms"
    ) {
      setNumberOfBoxexRequired(180);
      setBoxableVolume(540);
    } else if (
      selectedRadio === "pack-rat" &&
      totalBedrooms.target.value.value === "6+Bedrooms"
    ) {
      setNumberOfBoxexRequired(235);
      setBoxableVolume(705);
    }
  };

  useEffect(() => {
    if (numberOfBoxexRequired !== null && boxableVolume !== null) {
      updateThisMoveRequest({
        mover_category: selectedRadio,
        total_bedrooms: totalBedrooms.target.value.value,
        no_of_boxes_for_boxable_items: Number(numberOfBoxexRequired),
        boxable_items_volume: Number(boxableVolume),
      });
    }
  }, [numberOfBoxexRequired, boxableVolume]);
  const currentTenant = process.env.REACT_APP_TENANT;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center" // Centers the logo and text
        width="100vw" // Full width of the viewport
        sx={{ mt: 2 }} // Adds some margin to give spacing from the top
      >
        {currentTenant === "wemove" || currentTenant === "sold" ? (
          <img
            src={logoWeMove}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        ) : currentTenant === "turbotenant" ? (
          <img
            src={TurboTenantLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : currentTenant === "movebees" ? (
          <img
            src={MoveBeesLogo}
            alt=""
            style={{ width: "240px", height: "66px" }}
          />
        ) : currentTenant === "summitmoving" ? (
          <img
            src={SummitMovingLogo}
            alt=""
            style={{ width: "150px", height: "60px" }}
          />
        ) : currentTenant === "krupp" ? (
          <img
            src={KruppLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : (
          <></>
        )}

        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          style={{ marginLeft: "10px" }}
        >
          {currentTenant === "wemove" || currentTenant === "sold"
            ? "WeMove.ai"
            : currentTenant === "turbotenant"
              ? "TurboTenant"
              : currentTenant === "movebees"
                ? "Movebees"
                : currentTenant === "krupp"
                  ? "Krupp Moving"
                  : currentTenant === "summitmoving"
                    ? "Summit Moving"
                    : ""}
        </Typography>
      </Stack> */}
      <LogoHeader />
      <Grid container>
        <Grid item xs={12} md={8} p={2} marginX={"auto"}>
          {/* {process.env.REACT_APP_TENANT==='wemove'||process.env.REACT_APP_TENANT==='turbotenant'&&(
        <Stack mt={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
            <ContactSupport/>
          </Stack>
          )}
          <ProgressBar value={80} /> */}
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 }, // Responsive padding for small screens
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <NeedHelp />
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Delivery Location Type
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Fill in all info correctly
                  </Typography>
                </Stack>
                <CustomCircularProgress value={8} total={9} />
              </Stack>
            </Stack>
            <Typography mt={3} fontSize={16} fontWeight={600}>
              To help us determine the number of boxes needed for your move,
              please answer the following 2 questions:
            </Typography>

            <FormControl sx={{ mt: 3 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Tell us a little about yourself.
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedRadio}
                onChange={(e) => setSelectedRadio(e.target.value)}
              >
                <FormControlLabel
                  value="minimalist"
                  control={<Radio />}
                  label="Are you a minimalist?"
                />
                <FormControlLabel
                  value="average-collector"
                  control={<Radio />}
                  label="Are you an average collector?"
                />
                <FormControlLabel
                  value="pack-rat"
                  control={<Radio />}
                  label="Are you a pack rat?"
                />
              </RadioGroup>
            </FormControl>

            <Typography mt={2} fontSize={14} fontWeight={550}>
              How many total bedrooms are you moving (in your current location)?
            </Typography>

            <Stack
              alignItems={"center"}
              marginTop={2}
              direction={"row"}
              spacing={1}
            >
              <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">
                  {totalBedrooms !== ""
                    ? totalBedrooms.target.value.label
                    : "Select One"}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={""}
                  label="Select One"
                  onChange={(e) => {
                    setTotalBedrooms(e);
                  }}
                  required
                >
                  {bedroomsDropDown.map((size: any) => {
                    return (
                      <MenuItem key={size.value} value={size}>
                        {size.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>

            <Typography mt={3} fontSize={14} fontWeight={400}>
              The total number of boxes calculated can be modified by you in
              your inventory report all the way up until 1-day prior to your
              move. If you need to increase the number of boxes, simply add the
              + button and if you need to delete click the – button. If you add
              boxes your price will go up and if you remove boxes, your price
              will go down. It’s that simple.
            </Typography>
            <Stack
              mt={3}
              spacing={2}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  width: 120,
                  height: 45,
                  backgroundColor: "#E9E9EA",
                  color: "#808080",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  //  navigate(`/itemsInfo/${moveRequest.canonical_id}`);
                  if (totalBedrooms !== "") {
                    // updateThisMoveRequest({
                    //   mover_category: selectedRadio,
                    //   total_bedrooms: totalBedrooms.target.value.value
                    // })
                    calculateTotalBoxesforBoxableItems();
                  }
                }}
                variant="contained"
                size="medium"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Continue
              </Button>
            </Stack>
            {isPageLoading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isPageLoading}
                onClick={() => {
                  setIsPageLoading(true);
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BoxingInfo;
