import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import VerticalBarIcon from "../../assets/Icons/verticalBarIcon.png";
import { useEffect, useState } from "react";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import TwoDaysAlertModal from "../../components/modals/twoDaysAlertModal";
import ProgressBar from "../../components/progressBar/progressBar";
import NavBar from "../../components/navbar/navBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { CustomCircularProgress } from "../../components/circularProgress";
import { NeedHelp } from "../../components/needHelp";
import LogoHeader from "../../components/logo-header/logoHeader";

const datePickerStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: 1.5,
      borderColor: "#5A7BFC33",
      borderRadius: "8px",
    },
  },
};

const Pickupdate = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  ReactGA.send({
    hitType: "pageview",
    page: "/PickupDate/:moveRequestId",
    title: "Move date",
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  const [isTwoDaysAlertModal, setIsTwoDaysAlertModal] = useState(false);

  const [fromDate, setFromDate] = useState<any>(dayjs(""));
  const [toDate, setToDate] = useState<any>(dayjs(""));
  const [deliveryDate, setDeliveryDate] = useState<any>(dayjs(""));

  const [formError, setFormError] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);

  const handleDateChange = (newValue: any, targetDate: string) => {
    if (newValue) {
      if (targetDate === "fromDate") {
        const newFromDate = dayjs(newValue).startOf("day");
        const newToDate = newFromDate.add(1, "day");

        setFromDate(newFromDate);
        setToDate(newFromDate);
      } else if (targetDate === "deliveryDate") {
        const newDeliveryDate = dayjs(newValue).startOf("day");
        setDeliveryDate(newDeliveryDate);
      }
    }
  };

  const navigateToLastLeft = (moveRequest: any) => {
    if (!moveRequest.delivery_details) {
      navigate(`/AddressInfo/${moveRequest.canonical_id}`);
    } else {
      if (moveRequest.delivery_details?.delivery_addresses?.length === 0) {
        navigate(`/AddressInfo/${moveRequest.canonical_id}`);
      } else {
        if (
          moveRequest.delivery_details?.delivery_addresses[0]
            .delivery_location_type === null
        ) {
          navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
        } else {
          if (
            moveRequest.pickup_date_from === null &&
            moveRequest.pickup_date_to === null
          ) {
            navigate(`/PickupDate/${moveRequest.canonical_id}`);
          } else {
            if (moveRequest.delivery_details.open_location === null) {
              navigate(`/TruckInfo/${moveRequest.canonical_id}`);
            } else {
              // if (moveRequest.items?.length === 0) {
              navigate(`/itemsInfo/${moveRequest.canonical_id}`);
              // }
              // else {
              //   if (
              //     moveRequest.delivery_details?.packagaing_required === null
              //   ) {
              //     navigate(`/packages/${moveRequest.canonical_id}`);
              //   } else {
              //     navigate(`/move-summary/${moveRequest.canonical_id}`);
              //   }
              // }
            }
          }
        }
      }
    }
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateThisMoveRequest = (body: any) => {
    const params = {
      id: thisMoveRequestId,
      ...body,
    };

    setFormError("");
    if (fromDate.isValid()) {
      if (deliveryDate !== "" && deliveryDate.isValid()) {
        if (deliveryDate.isAfter(toDate)) {
          setIsPageLoading(true);

          dispatch<any>(updateMoveRequestById(params))
            .then(unwrapResult)
            .then((thisMoveRequest: any) => {
              setTimeout(() => {
                setIsPageLoading(false);
                navigate(`/TruckInfo/${thisMoveRequest.canonical_id}`);
                // navigateToLastLeft(thisMoveRequest);
              }, 1300);
            })
            .catch((err: any) => {
              console.log(err);
              setTimeout(() => {
                setIsPageLoading(false);
              }, 1300);
            });
        } else {
          setIsPageLoading(false);
          setFormError("Delivery Date should be after Pickup date.");
        }
      } else {
        setIsPageLoading(true);
        dispatch<any>(updateMoveRequestById(params))
          .then(unwrapResult)
          .then((thisMoveRequest: any) => {
            setTimeout(() => {
              setIsPageLoading(false);
              navigate(`/TruckInfo/${thisMoveRequest.canonical_id}`);
              // navigateToLastLeft(thisMoveRequest);
            }, 1300);
          })
          .catch((err: any) => {
            console.log(err);
            setTimeout(() => {
              setIsPageLoading(false);
            }, 1300);
          });
      }
    } else {
      setFormError("Please select a pick-up date.");
    }
  };

  useEffect(() => {
    fetchMoveRequestById(moveRequestId);
  }, []);
  const currentTenant = process.env.REACT_APP_TENANT;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center" // Centers the logo and text
        width="100vw" // Full width of the viewport
        sx={{ mt: 2 }} // Adds some margin to give spacing from the top
      >
        {currentTenant === "wemove" || currentTenant === "sold" ? (
          <img
            src={logoWeMove}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        ) : currentTenant === "turbotenant" ? (
          <img
            src={TurboTenantLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : currentTenant === "movebees" ? (
          <img
            src={MoveBeesLogo}
            alt=""
            style={{ width: "240px", height: "66px" }}
          />
        ) : currentTenant === "summitmoving" ? (
          <img
            src={SummitMovingLogo}
            alt=""
            style={{ width: "150px", height: "60px" }}
          />
        ) : currentTenant === "krupp" ? (
          <img
            src={KruppLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : (
          <></>
        )}

        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          style={{ marginLeft: "10px" }}
        >
          {currentTenant === "wemove" || currentTenant === "sold"
            ? "WeMove.ai"
            : currentTenant === "turbotenant"
              ? "TurboTenant"
              : currentTenant === "movebees"
                ? "Movebees"
                : currentTenant === "krupp"
                  ? "Krupp Moving"
                  : currentTenant === "summitmoving"
                    ? "Summit Moving"
                    : ""}
        </Typography>
      </Stack> */}
      <LogoHeader />
      {/* <NavBar moveRequestId={moveRequestId} /> */}
      <Grid container>
        <Grid item xs={12} sm={6} md={8} p={2} marginX="auto">
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 }, // Responsive padding for small screens
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <NeedHelp />
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Date of move
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Fill in all info correctly
                  </Typography>
                </Stack>
                <CustomCircularProgress value={6} total={9} />
              </Stack>
            </Stack>

            <Stack mt={2} alignItems={"center"} direction={"row"}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={"#262626"}
                textAlign={"start"}
              >
                What is your move date?
              </Typography>
            </Stack>

            <DemoContainer
              sx={{
                padding: 3,
              }}
              components={[
                "DatePicker",
                "DesktopDatePicker",
                "MobileDatePicker",
              ]}
            >
              <DatePicker
                value={fromDate}
                onChange={(newValue) => handleDateChange(newValue, "fromDate")}
                slotProps={{ textField: { size: "small" } }}
                sx={datePickerStyle}
                minDate={dayjs().startOf("day")}
              />

              {/* <Box display={"flex"} justifyContent={"start"}>
              <img src={VerticalBarIcon} width={2} alt="" />
            </Box>

            <Typography fontSize={16} color={"#797979"} textAlign={"start"}>
              To
            </Typography> */}
              {/* <DatePicker
              value={toDate}
              readOnly
              slotProps={{ textField: { size: "small" } }}
              sx={datePickerStyle}
            /> */}

              {/* <Typography
                mt={3}
                fontSize={14}
                fontWeight={500}
                color={"#262626"}
                textAlign={"start"}
              >
                First available date of delivery? (optional)
              </Typography>
              <DatePicker
                value={deliveryDate}
                onChange={(newValue) =>
                  handleDateChange(newValue, "deliveryDate")
                }
                slotProps={{ textField: { size: "small" } }}
                sx={datePickerStyle}
                minDate={toDate}
              /> */}
            </DemoContainer>

            <Box p={3}>
              {formError && <Alert severity="error">{formError}</Alert>}
            </Box>

            <Stack
              direction="row"
              mt={3}
              spacing={2}
              justifyContent={"center"}
              alignSelf={"center"}
            >
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  width: 140,
                  height: 45,
                  backgroundColor: "#E9E9EA",
                  color: "#808080",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  updateThisMoveRequest({
                    first_available_date_of_delivery: deliveryDate.isValid()
                      ? deliveryDate.format("YYYY-MM-DD")
                      : null,
                    pickup_date_from: fromDate.format("YYYY-MM-DD"),
                    pickup_date_to: toDate.format("YYYY-MM-DD"),
                  });
                }}
                size="medium"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Pickupdate;
