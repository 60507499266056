import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Store } from "./store";
import WeMovePackages from "./pages/weMovePackages/weMovePackages";
import Pickupdate from "./pages/pickupDate/pickupdate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddressInfo from "./pages/addressInfo/addressInfo";
import DeliveryLocationInfo from "./pages/deliveryLocationInfo/deliveryLocationInfo";
import TruckInfo from "./pages/truckInfo/truckInfo";
import VideoGuidelines from "./pages/videoGuidelines/videoGuidlines";
import ItemsInfo from "./pages/itemsInfo/itemsInfo";
import CarrierInfo from "./pages/carrierInfo/carrierInfo";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import Login from "./pages/login/login";
import OtpVerification from "./pages/otp/otpVerification";
import DeliveryCarrierDashboard from "./pages/deliveryCarrierDashboard/deliveryCarrierDashboard";
import PickupCarrierDashboard from "./pages/pickupCarrierDashboard/pickupCarrierDashboard";
import ProtectedRoute from "./routes/protectedRoute";
import Register from "./pages/register/register";
import AdminLogin from "./pages/adminLogin/adminLogin";
import AdminDashboard from "./pages/adminDashboard/adminDashboard";
import VideoRecorder from "./pages/videoRecorder/videoRecorder";
import MoveSummary from "./pages/moveSummary/moveSummary";
import ContractDocument from "./pages/contractDocument/contractDocument";
import UploadInventory from "./pages/uploadInventory/uploadInventory";
import InventoryPopulate from "./pages/inventoryPopulation/inventoryPopulate";
import AddInventory from "./pages/inventoryPopulation/addInventory";
import MoveSuccess from "./pages/moveSuccess/moveSuccess";
import AppDrawer from "./components/appDrawer/appDrawer";
import BoxingInfo from "./pages/boxingInfo/boxingInfo";
import ModifyItems from "./pages/modifyItems/modifyItems";
import { LandingPage } from "./pages/landingPage/landingPage";
import ConsumerSignup from "./pages/consumerSignup/consumerSignup";
import UserVerification from "./pages/userVerification/userVerification";
import ReactGA from "react-ga4";
import BookedMove from "./pages/bookedMove/bookedMove";
import { PageNotFound } from "./pages/notFound/notFound";
import EsignatureDocument from "./components/EsignatureDocument";
import SuccessMessage from "./pages/inventoryPopulation/successMessage";
function App() {
  if (process.env.REACT_APP_URL === "https://staging.wemoveai.com") {
    ReactGA.initialize("G-1ZB92KEDQC");
  } else if (process.env.REACT_APP_URL === "https://production.wemoveai.com") {
    ReactGA.initialize("G-DNQ1FSDK2P");
  } else if (process.env.REACT_APP_URL === "https://turbotenant.wemoveai.com") {
    ReactGA.initialize("G-Y08FZ686VJ");
  } else if (process.env.REACT_APP_URL === "https://sold.wemoveai.com") {
    ReactGA.initialize("G-RNLE80VZY3");
  } else if (
    process.env.REACT_APP_URL === "https://summitmoving.wemoveai.com"
  ) {
    ReactGA.initialize("G-9XRN4VT6W0");
  } else if (process.env.REACT_APP_URL === "https://kruppmoving.wemoveai.com") {
    ReactGA.initialize("G-TZDHLXE4Q1");
  } else if (process.env.REACT_APP_URL === "https://movebees.wemoveai.com") {
    ReactGA.initialize("G-7YV0WBKMNE");
  } else if (
    process.env.REACT_APP_URL === "https://rockstarsprocomplete.wemoveai.com"
  ) {
    ReactGA.initialize("G-CM9YWY4C4M");
  } else if (
    process.env.REACT_APP_URL === "https://rockstarspropartial.wemoveai.com"
  ) {
    ReactGA.initialize("G-8JM2KCDCS4");
  } else if (
    process.env.REACT_APP_URL === "https://safewaymoving.wemoveai.com"
  ) {
    ReactGA.initialize("G-VG75HTHS82");
  } else if (
    process.env.REACT_APP_URL === "https://dearmanmoving.wemoveai.com"
  ) {
    ReactGA.initialize("G-7DE0DG20QZ");
  } else if (
    process.env.REACT_APP_URL === "https://royalmovingco.wemoveai.com"
  ) {
    ReactGA.initialize("G-C9JYN2R1MB");
  }

  if (process.env.REACT_APP_URL === "http://localhost.wemoveai.com:3000") {
    ReactGA.initialize("G-23LW5XLB9C");
  }
  const prodTenant = process.env.REACT_APP_URL;
  const faviconUrl =
    prodTenant === "https://production.wemoveai.com"
      ? "/drift-favicon.png" // Public folder path
      : "/favicon.ico"; // Public folder path

  const linkElement = document.getElementById("favicon") as HTMLLinkElement;
  if (linkElement) {
    linkElement.href = faviconUrl;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Provider store={Store}>
            <Router>
              <Routes>
                <Route
                  path="deliveryCarrierDashboard/:canonicalId"
                  element={<DeliveryCarrierDashboard />}
                />
              </Routes>

              <Routes>
                <Route path="/Login" element={<Login />} />
              </Routes>
              <Routes>
                <Route
                  path="/booked-move/:moveRequestId"
                  element={<BookedMove />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/inventory-guidelines/:moveRequestId"
                  element={<VideoGuidelines />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/video-recorder/:moveRequestId"
                  element={<VideoRecorder />}
                />
                {/* <Route path="/consumer-signup" element={<ConsumerSignup />} /> */}
              </Routes>

              <Routes>
                <Route
                  path="/upload-inventory/:moveRequestId"
                  element={<UploadInventory />}
                />
              </Routes>
              <Routes>
                <Route path="/not-found" element={<PageNotFound />} />
              </Routes>

              <Routes>
                <Route path="/carriers-onboarding" element={<Register />} />
              </Routes>

              <Routes>
                <Route path="/admin-login" element={<AdminLogin />} />
              </Routes>

              <Routes>
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
              </Routes>
              <Routes>
                <Route path="/otpVerificaion" element={<OtpVerification />} />
              </Routes>

              <Routes>
                <Route
                  path="/packages/:moveRequestId"
                  element={<WeMovePackages />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/PickupDate/:moveRequestId"
                  element={<Pickupdate />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/AddressInfo/:moveRequestId"
                  element={<AddressInfo />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/DeliveryLocationInfo/:moveRequestId"
                  element={<DeliveryLocationInfo />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/TruckInfo/:moveRequestId"
                  element={<TruckInfo />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/itemsInfo/:moveRequestId"
                  element={<ItemsInfo />}
                />
              </Routes>
              <Routes>
                <Route
                  path="/modifyItems/:moveRequestId"
                  element={<ModifyItems />}
                />
              </Routes>

              <Routes>
                <Route path="/CarrierInfo" element={<CarrierInfo />} />
              </Routes>

              <Routes>
                <Route
                  path="/pickupCarrierDashboard/:canonicalId"
                  element={<PickupCarrierDashboard />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/ConsumerDashboard/:consumerCanonicalId"
                  element={
                    // <ProtectedRoute allowedRoles={["consumer"]}>
                    <AppDrawer />
                    // </ProtectedRoute>
                  }
                />
                {/* <Route path="/ConsumerDashboard/:consumerId" element={<ProtectedRoute allowedRoles={['consumer']}><ConsumerDashboard /></ProtectedRoute>} /> */}
              </Routes>

              <Routes>
                <Route
                  path="/move-summary/:moveRequestId"
                  element={<MoveSummary />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/contract/:moveRequestId"
                  element={<ContractDocument />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/move-success/:moveRequestId"
                  element={<MoveSuccess />}
                />
              </Routes>

              <Routes>
                <Route
                  path="/boxing-info/:moveRequestId"
                  element={<BoxingInfo />}
                />
              </Routes>

              <Routes>
                <Route path="/landing-page" element={<LandingPage />} />
              </Routes>

              <Routes>
                <Route
                  path="/user-verification/:moveRequestId"
                  element={<UserVerification />}
                />
              </Routes>
              <Routes>
                <Route path="/start-move" element={<AppDrawer />} />
              </Routes>
              <Routes>
                <Route
                  path="/EsignatureDocument/:moveRequestId"
                  element={<EsignatureDocument />}
                />
              </Routes>
              <Routes>
                <Route
                  path="/inventory-populate"
                  element={<InventoryPopulate />}
                />
              </Routes>
              <Routes>
                <Route
                  path="/add-inventory/:phoneNumber"
                  element={<AddInventory />}
                />
              </Routes>
              <Routes>
                <Route path="/success-message" element={<SuccessMessage />} />
              </Routes>
            </Router>
          </Provider>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
