import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import {
  Divider,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import apartmentImage from "../../assets/images/new-apartment.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import comboApartmentStorage from "../../assets/images/Combo-Apartment-Storage.svg";
import homeOnly from "../../assets/images/Home-only.svg";
import comboHomeStorage from "../../assets/images/Combo-Home-Storage.svg";
import storageOnly from "../../assets/images/Storage-only.svg";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomCircularProgress } from "../circularProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NeedHelp } from "../needHelp";
import ReactGA from "react-ga4";

const OutOfStateMoveModal = ({
  outOfStateMoveModalVisible,
  setOutOfStateMoveModalVisible,
  createMoveRequest,
  thisMoveRequest,
  moveType,
  setMoveType,
  isLoading,
  setIsLoading,
  setIsServiceSelectionModal,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const typeSelected = {
    width: 190,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FDFCFD",
    border: `1px solid ${theme.palette.primary.main}`,
  };

  const typeNotSelected = {
    width: 190,
    color: "#5859DF",
  };
  const [numberOfBedrooms, setNumberOfBedrooms] = useState("");
  // const [availableDate, setAvailableDate] = useState("");
  const [fieldError, setFieldError] = useState("");

  const handleClose = () => setOutOfStateMoveModalVisible(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNoChange = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    const maxLength = 10;

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    setPhoneNumber(inputValue);

    if (inputValue.length !== maxLength) {
      setPhoneNoError("Please enter a valid 10-digit phone number");
    } else {
      setPhoneNoError("");
    }
  };
  const [phoneNoError, setPhoneNoError] = useState("");
  const [selectedTab, setSelectedTab] = useState("single");
  return (
    <>
      <Box
        sx={{
          width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "50vw" },
          height: isMobile ? "90vh" : "90vh",
          overflow: "auto",
          borderRadius: "8px",
          padding: 2,
          border: "1px solid #0000001A",
          backgroundColor: "#FBFBFB",
          mt: 5,
          alignSelf: "center",
        }}
      >
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {
              setIsLoading(true);
            }}
          >
            <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
              <CircularProgress color="inherit" />
            </Stack>
          </Backdrop>
        )}

        {/* <Button
          onClick={() => {
            setMoveType("");
            setIsServiceSelectionModal(true);
          }}
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          <ArrowBackIcon
            sx={{ color: theme.palette.primary.main }}
            fontSize="large"
          />
        </Button> */}

        <NeedHelp />
        <Stack
          sx={{
            alignItems: isMobile ? "center" : "flex-start",
            overflow: "auto",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"80%"}
          >
            <Stack>
              <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                Enter Location
              </Typography>
              <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                Choose the type of your pickup location.
              </Typography>
            </Stack>
            <CustomCircularProgress value={2} total={8} />
          </Stack>
        </Stack>

        <Box
          sx={{
            width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" },
            margin: "auto",
            mt: 5,
            p: 2,
            backgroundColor: "#F9F9F9",
            borderRadius: 2,
          }}
        >
          {/* Tab Section */}
          <Stack
            direction="row"
            spacing={0.5}
            justifyContent="center"
            sx={{ mb: 2 }}
          >
            <Button
              onClick={() => setSelectedTab("single")}
              sx={{
                backgroundColor: selectedTab === "single" ? "#F9F9F9" : "#fff",
                color: selectedTab === "single" ? "#1976d2" : "#646464",
                borderRadius: "10px",
                border:
                  selectedTab === "single"
                    ? "2px solid #1976d2"
                    : "2px solid #e0e0e0",
                fontWeight: 600,
                textTransform: "none",
                p: 1.5,
                minWidth: "140px",
                boxShadow:
                  selectedTab === "single"
                    ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    : "none",
              }}
            >
              Single Unit's
            </Button>
            <Button
              onClick={() => setSelectedTab("combos")}
              sx={{
                backgroundColor: selectedTab === "combos" ? "#F9F9F9" : "#fff",
                color: selectedTab === "combos" ? "#1976d2" : "#646464",
                borderRadius: "10px",
                border:
                  selectedTab === "combos"
                    ? "2px solid #1976d2"
                    : "2px solid #e0e0e0",
                fontWeight: 600,
                textTransform: "none",
                p: 1.5,
                minWidth: "140px",
                boxShadow:
                  selectedTab === "combos"
                    ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    : "none",
              }}
            >
              Combo's
            </Button>
          </Stack>

          <Divider sx={{ mb: 3 }} />

          {/* Content Section */}
          {selectedTab === "single" ? (
            <Stack spacing={2}>
              {/* Apartment Only Card */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  borderBottom:
                    moveType === "apartment-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                  borderbottom:
                    moveType === "apartment-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                }}
              >
                <Stack>
                  <Typography
                    sx={{ fontSize: 14, color: "#7d7d7d", fontWeight: 500 }}
                  >
                    I'm Moving
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                    Apartment only
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    setMoveType("apartment-only");
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "#1976d2" }} />
                </Button>
              </Box>

              {/* Home Only Card */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  borderBottom:
                    moveType === "home-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                  borderbottom:
                    moveType === "home-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                }}
              >
                <Stack>
                  <Typography
                    sx={{ fontSize: 14, color: "#7d7d7d", fontWeight: 500 }}
                  >
                    I'm Moving
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                    Home only
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    setMoveType("home-only");
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "#1976d2" }} />
                </Button>
              </Box>

              {/* Storage Only Card */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  borderBottom:
                    moveType === "storage-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                  borderbottom:
                    moveType === "storage-only"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                }}
              >
                <Stack>
                  <Typography
                    sx={{ fontSize: 14, color: "#7d7d7d", fontWeight: 500 }}
                  >
                    I'm Moving
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                    Storage only
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    setMoveType("storage-only");
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "#1976d2" }} />
                </Button>
              </Box>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  borderBottom:
                    moveType === "combo-apartment-storage"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                  borderbottom:
                    moveType === "combo-apartment-storage"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                }}
              >
                <Stack>
                  <Typography
                    sx={{ fontSize: 14, color: "#7d7d7d", fontWeight: 500 }}
                  >
                    I'm Moving
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                    Combo Apartment Storage
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    setMoveType("combo-apartment-storage");
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "#1976d2" }} />
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  borderBottom:
                    moveType === "combo-home-storage"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                  borderbottom:
                    moveType === "combo-home-storage"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                }}
              >
                <Stack>
                  <Typography
                    sx={{ fontSize: 14, color: "#7d7d7d", fontWeight: 500 }}
                  >
                    I'm Moving
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                    Combo Home Storage
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    setMoveType("combo-home-storage");
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "#1976d2" }} />
                </Button>
              </Box>
            </Stack>
          )}
        </Box>

        <Stack p={2} alignItems="center">
          {fieldError && <Alert severity="error">{fieldError}</Alert>}

          <Button
            onClick={() => {
              if (moveType !== "") {
                ReactGA.send({
                  hitType: "pageview",
                  page: "/start-move",
                  title: `Selected Move Type ${moveType}`,
                });
                createMoveRequest({
                  id: thisMoveRequest.id,
                  location_type: moveType,
                });
              } else {
                if (moveType === "") {
                  setFieldError("Please select the location type");
                } else if (phoneNumber === "") {
                  setFieldError("Please enter the phone number");
                }
              }
            }}
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 13,
              width: 180,
              height: 40,
            }}
            disabled={moveType === ""}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default OutOfStateMoveModal;
