export const getCarrierTitle = () => {
  if (
    process.env.REACT_APP_TENANT === "wemove" ||
    process.env.REACT_APP_TENANT === "turbotenant" ||
    process.env.REACT_APP_TENANT === "sold"
  ) {
    return "WeMove";
  } else if (process.env.REACT_APP_TENANT === "rockstarspromoverscomplete") {
    return "Rockstar Pro Movers";
  } else if (process.env.REACT_APP_TENANT === "elevatedliving") {
    return "Elevated Living";
  } else if (process.env.REACT_APP_TENANT === "harrington-movers") {
    return "Harrington Movers";
  } else if (process.env.REACT_APP_TENANT === "dependable-movers") {
    return "Dependable Movers";
  } else return "WeMove";
};
