import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const SuccessMessage = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 600,
          marginBottom: 2,
        }}
      >
        Thank You for Uploading Images!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#555",
          marginBottom: 4,
        }}
      >
        Your images have been successfully uploaded. You can now proceed back
        with the carrier.
      </Typography>
    </Box>
  );
};

export default SuccessMessage;
