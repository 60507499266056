import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  autoRedirectToInventory,
  getMoveRequestById,
  shouldAutoRedirect,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import Backdrop from "@mui/material/Backdrop";
import * as AWS from "aws-sdk";
import {
  createRoomDetails,
  fetchInventoryFromImage,
  fetchInventoryFromVideo,
} from "../../redux/actions/room-details";
import UploadCompletedModal from "../../components/modals/uploadCompletedModal";
import imageCompression from "browser-image-compression";
import ReactGA from "react-ga4";
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ContactSupport from "../../components/contactSupport";
import NavBar from "../../components/navbar/navBar";
import { CustomCircularProgress } from "../../components/circularProgress";
import LogoHeader from "../../components/logo-header/logoHeader";
import { PlayCircleOutline } from "@mui/icons-material";

interface PayloadObject {
  title: string;
  images: File[]; // Specify the type as File[]
}
const awsS3Bucket: string = process.env.REACT_APP_AWS_S3_BUCKET || "";
const awsAccessKey = process.env.REACT_APP_AWS_S3_ACCESS_KEY;
const awsSecretKey = process.env.REACT_APP_AWS_S3_KEY_SECRET;

const s3 = new AWS.S3({
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretKey,
  region: process.env.REACT_APP_AWS_S3_REGION,
});

const AddInventory = () => {
  const [urlArray, setUrlArray] = useState<string[]>([]);
  const { phoneNumber } = useParams<{ phoneNumber: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
  // const [jwt, setJwt] = useState<any>("")
  const [totalVideos, setTotalVideos] = useState<any>([]);
  const [videoCount, setVideoCount] = useState(0);
  const [vidCount, setVidCount] = useState(0);
  const [videoTitlesArray, setVideoTItlesArray] = useState([""]);

  const [nextState, setNextState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [uploadCompletedModalVisible, setUploadCompletedModalVisible] =
    useState(false);
  const [imagePreviews, setImagePreviews] = useState<string[][]>([]);

  const sessionId = new URLSearchParams(window.location.search).get(
    "sessionId"
  );

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem("sessionId", sessionId);
      localStorage.setItem("flowStartedOn", "PC");
      const urlWithoutSessionId =
        window.location.pathname +
        window.location.search
          .split("&")
          .filter((param) => !param.includes("sessionId"))
          .join("&");
      window.history.replaceState(null, "", urlWithoutSessionId);
    } else if (isMobile && !localStorage.getItem("sessionId")) {
      localStorage.setItem("flowStartedOn", "Mobile");
    } else if (!isMobile && !localStorage.getItem("sessionId")) {
      localStorage.setItem("flowStartedOn", "PC");
    }

    // if (!isMobile && localStorage.getItem('flowStartedOn') === 'PC') {
    //   alert("Please complete this task on your mobile device.");
    // }
  }, [sessionId]);

  const uploadFile = async (file: any, fileName: any) => {
    const formData = new FormData();
    formData.append("file", file);
    return await s3_upload(formData, fileName, file.name);
  };

  const s3_upload = async (
    formData: any,
    fileName: any,
    originalName: string
  ) => {
    // const contentType = getContentType(originalName);
    const uploadParams = {
      Bucket: awsS3Bucket,
      Key: fileName,
      Body: formData.get("file"),
      ContentType: "image/png",
    };
    return new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err: any, data: any) => {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
    });
  };

  const retrieveFormDataAndSaveToS3 = async (
    file: any,
    fileName: string,
    videoTitle: string
  ) => {
    setIsLoading(true);
    setLoadingMessage(`Uploading Image...`);

    const responseObject: any = await uploadFile(file, fileName);
    setLoadingMessage(`Fetching inventory...`);

    if (responseObject.Location) {
      const body = {
        moveRequestId: thisMoveRequestId,
        video_url: responseObject.Location,
        title: videoTitle,
      };

      dispatch<any>(fetchInventoryFromVideo(body))
        .then(unwrapResult)
        .then((res: any) => {
          setVideoCount((videoCounter) => videoCounter + 1);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
          setLoadingMessage("");
        });
    }
  };
  const [formError, setFormError] = useState("");
  const handleTitleChange = (title: string, index: any) => {
    const newTitles = [...videoTitlesArray];
    newTitles[index] = title;
    setVideoTItlesArray(newTitles);
  };

  const handleAddMore = () => {
    const allFilled = totalVideos.every((video: any) => video.name);
    if (allFilled) {
      setTotalVideos([...totalVideos, {}]);
    } else {
      console.warn("Please fill all previous video fields before adding more.");
    }
  };

  const handleFileChange = (event: any, index: any) => {
    setNextState(false);
    const newSelectedFiles = [...totalVideos];
    let count = index + 1;
    newSelectedFiles[index] = event.target.files[0];
    setTotalVideos(newSelectedFiles);
  };

  const countTotalVideos = () => {
    totalVideos.map((video: any) => {
      if (video.type) {
        setVidCount((count) => count + 1);
      }
    });
  };
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const uploadToS3 = async (imagesArray: any[]) => {
    console.log("Uploading images to get inventory...");
    const tempUrlArray: string[] = [];
    const uploadPromises = imagesArray.flatMap(
      (payload: any, payloadIndex: number) =>
        payload.images.map(async (image: any, imageIndex: number) => {
          imageIndex = imageIndex + 1;
          const imageName = `carrier-inventory/${payload.title}/image-index/${payloadIndex}-${imageIndex}`;
          console.log(
            `Uploading image: ${image.name}, ImageName: ${imageName}`
          );

          const responseObject: any = await uploadFile(image, imageName);
          if (responseObject.Location) {
            urlArray.push(responseObject.Location);
          }
        })
    );
    await Promise.all(uploadPromises);
    setUrlArray((prevArray) => [...prevArray, ...tempUrlArray]);
    setIsUploadComplete(true);
    return urlArray;
  };

  const [imageCount, setImageCount] = useState(0);
  const [processedImages, setProcessedImages] = useState(0);
  const countImages = () => {
    payloadObjects.map((payload: any) => {
      payload.images.map((img: any) => {
        if (img.type) {
          setImageCount((imgCount) => imgCount + 1);
        }
      });
    });
  };
  useEffect(() => {
    if (isUploadComplete && urlArray.length > 0 && phoneNumber) {
      const body = {
        urlArray: urlArray,
        phoneNumber: phoneNumber ?? "",
      };
      setLoadingMessage(`Fetching inventory...`);
      dispatch<any>(fetchInventoryFromImage(body))
        .then(unwrapResult)
        .then((res: any) => {
          console.log("Inventory fetched successfully!", res);
          setIsUploadComplete(false);
        })
        .catch((err: any) => {
          console.log("Error fetching inventory:", err);
          setIsLoading(false);
          setLoadingMessage("");
          setIsUploadComplete(false);
        });
      setLoadingMessage(`Redirecting to Page...`);
      navigate("/success-message");
    }
  }, [isUploadComplete, urlArray, phoneNumber]);
  useEffect(() => {
    setIsLoading(false);
    const body = {
      phone: phoneNumber,
    };
    const { phone } = body;
    if (phoneNumber === phone) {
      dispatch<any>(autoRedirectToInventory(body))
        .then(unwrapResult)
        .then((res: any) => {
          console.log("Redirect Signal sent!");
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    console.log(localStorage.getItem("action-type"));
    console.log(localStorage.getItem("action-type") === "edit");
  }, []);
  const handleUploadImages = async () => {
    if (
      payloadObjects.every(
        (payload: any) =>
          // payload.title &&
          payload.images.length && payload.images?.every((img: any) => img.type)
      )
    ) {
      countImages();

      ReactGA.send({
        hitType: "pageview",
        page: "/start-move/add-inventory",
        title: `Inventory images uploaded`,
      });

      const imagesArray = payloadObjects.map((payload: any) => ({
        title: payload.title,
        images: payload.images,
      }));
      try {
        setIsLoading(true);
        setLoadingMessage("Uploading images to S3. Please wait...");

        const urlArray = await uploadToS3(imagesArray);

        console.log("Final URL Array:", urlArray);

        setLoadingMessage("");
        setIsLoading(false);
      } catch (error) {
        console.error("Error uploading images:", error);
        setIsLoading(false);
        setLoadingMessage("");
      }
    } else {
      setIsLoading(false);
      setFormError(
        "Please capture or select at least one image for each room you have added. "
      );
    }
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log(thisMoveRequest, "thisMoveRequest---------");
        setMoveRequest(thisMoveRequest);
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    localStorage.setItem("role", "consumer");

    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);

  const [payloadObjects, setPayloadObjects] = useState<PayloadObject[]>([
    { title: "", images: [] },
  ]);
  const [newTitle, setNewTitle] = useState("");

  // Function to handle title change
  const handleTitleChange1 = (index: any, value: any) => {
    setFormError("");
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects[index].title = value;
    setPayloadObjects(updatedPayloadObjects);
  };

  // Function to handle adding an image to a payload object
  const addImageToPayload = async (index: any, image: any, imageIndex: any) => {
    console.log(image, "00000----------");
    setFormError("");
    setIsLoading(true);
    setLoadingMessage("Compressing Image...");
    const updatedPayloadObjects: PayloadObject[] = [...payloadObjects]; // Specify the type of updatedPayloadObjects
    const file: File = image.target.files[0]; // Specify the type of file

    const previewUrl = URL.createObjectURL(file);

    // Options for image compression
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    // Compress the image file
    const compressedFile = await imageCompression(file, options);
    if (compressedFile) {
      // Update the payload with the compressed file
      updatedPayloadObjects[index].images[imageIndex] = compressedFile;
      setPayloadObjects(updatedPayloadObjects);
    } else {
      updatedPayloadObjects[index].images[imageIndex] = file;
      setPayloadObjects(updatedPayloadObjects);
    }

    const updatedPreviews = [...imagePreviews];
    if (!updatedPreviews[index]) updatedPreviews[index] = [];
    updatedPreviews[index][imageIndex] = previewUrl;
    setImagePreviews(updatedPreviews);

    setIsLoading(false);
    setLoadingMessage("");
  };
  const addImageInput = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    if (
      updatedPayloadObjects[index].images.length === 0 ||
      updatedPayloadObjects[index].images.slice(-1)[0].type !== undefined
    ) {
      updatedPayloadObjects[index].images.push(new File([], ""));
      setPayloadObjects(updatedPayloadObjects);
    }
  };
  const discardRoom = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects.splice(index, 1);
    setPayloadObjects(updatedPayloadObjects);
  };

  const discardImage = (roomIndex: number, imageIndex: number) => {
    const updatedPayloadObjects = [...payloadObjects];

    const updatedPreviews = [...imagePreviews];

    // Clean up the preview URL
    URL.revokeObjectURL(updatedPreviews[roomIndex][imageIndex]);
    updatedPreviews[roomIndex].splice(imageIndex, 1);
    setImagePreviews(updatedPreviews);

    updatedPayloadObjects[roomIndex].images.splice(imageIndex, 1);
    setPayloadObjects(updatedPayloadObjects);
  };
  const currentTenant = process.env.REACT_APP_TENANT;

  return (
    <>
      <LogoHeader />
      <Grid container marginX={"auto"}>
        <Grid item xs={12} md={8} marginX={"auto"}>
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <Box sx={{ width: "50%" }}>
                <LinearProgress />
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  textAlign="center"
                  mt={1}
                >
                  {loadingMessage &&
                    loadingMessage.split("\n").map((line, index) => (
                      <Typography key={index} component="span" display="block">
                        {line}
                      </Typography>
                    ))}
                </Typography>
              </Box>
            </Backdrop>
          )}

          {uploadCompletedModalVisible && (
            <UploadCompletedModal
              setUploadCompletedModalVisible={setUploadCompletedModalVisible}
              uploadCompletedModalVisible={uploadCompletedModalVisible}
            />
          )}
          {process.env.REACT_APP_TENANT === "wemove" ||
            (process.env.REACT_APP_TENANT === "turbotenant" && (
              <Stack
                mt={3}
                alignSelf={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ContactSupport />
              </Stack>
            ))}
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 },
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Upload Photos
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Our AI will analyse your photos of each room,
                    <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                      garage, patio etc in one click
                    </Typography>
                  </Typography>
                </Stack>
                <CustomCircularProgress value={4} total={8} />
              </Stack>
            </Stack>
            {totalVideos.length > 0 &&
              totalVideos.map((stop: any, index: any) => (
                <Stack
                  key={index}
                  alignItems={"center"}
                  direction={"row"}
                  mt={3}
                  justifyContent={"space-between"}
                ></Stack>
              ))}

            <Stack
              sx={{
                height: { xs: "auto", sm: "auto", md: "auto" },
                p: { xs: 2, sm: 4 },
                mt: { xs: 3, sm: 5 },
                border: "1px solid #0000001A",
                borderRadius: "8px",
                overflow: "auto",
                backgroundColor: "#FFFFFF",
              }}
              spacing={2}
              mt={2}
              justifyContent={"center"}
            >
              {payloadObjects.map((payload, payloadIndex) => (
                <>
                  <Stack
                    alignItems={"center"}
                    spacing={2}
                    key={payloadIndex}
                    width="100%"
                    marginY={5}
                  >
                    {payloadObjects.length > 0 &&
                    (payload.title === "" ||
                      payload.images.some((image) => image.type === "")) ? (
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        sx={{
                          padding: 2,
                          border: "2px dashed #0257CF",
                          borderRadius: "8px",
                          backgroundColor: "#E4F0FF6B",
                          height: 150,
                          width: "95%",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={{ xs: 14, sm: 16 }}
                        >
                          Add Images
                        </Typography>
                        <Typography fontSize={{ xs: 10, sm: 12 }}>
                          Add 2-4 room photos to capture all large furniture
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      {payload.images.map((image: any, imageIndex) => (
                        <Stack
                          spacing={1}
                          alignItems="flex-start"
                          key={imageIndex}
                        >
                          {image.size === 0 && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <IconButton
                                color="primary"
                                component="label"
                                style={{ display: "block", margin: "10px 0" }}
                              >
                                <Stack alignItems={"center"}>
                                  <AddAPhotoIcon />
                                  <input
                                    type="file"
                                    capture="environment"
                                    onChange={(e) =>
                                      addImageToPayload(
                                        payloadIndex,
                                        e,
                                        imageIndex
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="caption">
                                    Take Photo
                                  </Typography>
                                </Stack>
                              </IconButton>

                              <IconButton
                                color="secondary"
                                component="label"
                                style={{ display: "block", margin: "10px 0" }}
                              >
                                <Stack alignItems={"center"}>
                                  <FileUploadIcon />
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      addImageToPayload(
                                        payloadIndex,
                                        e,
                                        imageIndex
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="caption">
                                    Upload
                                  </Typography>
                                </Stack>
                              </IconButton>
                            </Stack>
                          )}

                          <Box
                            sx={{
                              position: "relative",
                              width: { xs: 60, sm: 124 },
                              maxHeight: { xs: 60, sm: 124 },
                              borderRadius: 2,
                              overflow: "hidden",
                            }}
                          >
                            {imagePreviews[payloadIndex] &&
                              imagePreviews[payloadIndex][imageIndex] && (
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: { xs: 60, sm: 124 },
                                    maxHeight: { xs: 60, sm: 124 },
                                    borderRadius: 2,
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={
                                      imagePreviews[payloadIndex][imageIndex]
                                    }
                                    alt={`Preview ${imageIndex + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: 12,
                                    }}
                                  />

                                  <IconButton
                                    onClick={() =>
                                      discardImage(payloadIndex, imageIndex)
                                    }
                                    sx={{
                                      position: "absolute",
                                      top: 4,
                                      right: 4,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      zIndex: 1,
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    size="small"
                                  >
                                    <CancelIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              )}
                          </Box>
                        </Stack>
                      ))}
                    </Stack>

                    <Button
                      sx={{
                        width: 232,
                        borderRadius: 4,
                        height: 48,
                        marginBottom: 5,
                      }}
                      variant="contained"
                      onClick={() => addImageInput(payloadIndex)}
                      disabled={
                        payload.images.length > 0 &&
                        payload.images[payload.images.length - 1].type === ""
                      }
                    >
                      Add Image +
                    </Button>
                  </Stack>
                  <Divider />
                </>
              ))}
              {formError && <Alert severity="error">{formError}</Alert>}

              <Button
                onClick={() => {
                  handleUploadImages();
                }}
                variant="contained"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  alignSelf: "center",
                  borderRadius: 14,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddInventory;
