export const getLocalRates = (numOfMovers: number): number | undefined => {
  if (process.env.REACT_APP_TENANT === "elevatedliving") {
    if (numOfMovers === 2) {
      return 216;
    } else if (numOfMovers === 3) {
      return 276;
    } else if (numOfMovers === 4) {
      return 336;
    } else if (numOfMovers === 5) {
      return 420;
    } else if (numOfMovers === 6) {
      return 485;
    }
  } else if (process.env.REACT_APP_TENANT === "harrington-movers") {
    if (numOfMovers === 2) {
      return 170;
    } else if (numOfMovers === 3) {
      return 210;
    } else if (numOfMovers === 4) {
      return 250;
    } else if (numOfMovers === 5) {
      return 335;
    } else if (numOfMovers === 6) {
      return 385;
    }
  } else if (process.env.REACT_APP_TENANT === "rockstarspromoverscomplete") {
    if (numOfMovers === 2) {
      return 135;
    } else if (numOfMovers === 3) {
      return 175;
    } else if (numOfMovers === 4) {
      return 215;
    } else if (numOfMovers === 5) {
      return 265;
    } else if (numOfMovers === 6) {
      return 315;
    }
  } else {
    if (numOfMovers === 2) {
      return 180;
    } else if (numOfMovers === 3) {
      return 230;
    } else if (numOfMovers === 4) {
      return 280;
    } else if (numOfMovers === 5) {
      return 350;
    } else if (numOfMovers === 6) {
      return 410;
    }
  }
};

export const getLongDistancePricing = (
  distance: number
): number | undefined => {
  if (process.env.REACT_APP_TENANT === "elevatedliving") {
    if (distance > 100 && distance <= 250) {
      return 6.16;
    } else if (distance > 250 && distance <= 500) {
      return 7.68;
    } else if (distance > 500 && distance <= 1500) {
      return 9.2;
    } else if (distance > 1500 && distance <= 2250) {
      return 10.74;
    } else if (distance > 2250 && distance <= 3500) {
      return 12.28;
    }
  } else if (process.env.REACT_APP_TENANT === "harrington-movers") {
    if (distance > 100 && distance <= 250) {
      return 3.25;
    } else if (distance > 250 && distance <= 500) {
      return 4;
    } else if (distance > 500 && distance <= 1500) {
      return 5.75;
    } else if (distance > 1500 && distance <= 2250) {
      return 8.25;
    } else if (distance > 2250 && distance <= 3000) {
      return 9;
    } else if (distance > 3000) {
      return 11;
    }
  } else {
    if (distance > 100 && distance <= 250) {
      return 5.12;
    } else if (distance > 250 && distance <= 500) {
      return 6;
    } else if (distance > 500 && distance <= 1500) {
      return 6.75;
    } else if (distance > 1500 && distance <= 2250) {
      return 8;
    } else if (distance > 2250 && distance <= 3500) {
      return 9;
    }
  }
};

export const getMoveCommissionPercentage = (
  moveType: string
): number | undefined => {
  if (process.env.REACT_APP_TENANT === "elevatedliving") {
    return 0.3;
  } else {
    if (moveType === "local") {
      return 0.129;
    } else if (moveType === "long-distance") {
      return 0.279;
    }
  }
};
