import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCheckoutSession,
  getMoveRequestById,
  submitEsignatureForm,
} from "../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga4";
import { DocusealForm } from "@docuseal/react";
import { getMoveCommissionPercentage } from "../assets/utils/getMoveRates";
import { getCarrierEmail } from "../assets/utils/getCarrierEmail";
import { getCarrierTitle } from "../assets/utils/getCarrierTitle";
import { callSupportContact } from "../assets/utils/getCarrierContact";
interface Field {
  name: string;
  default_value: any;
  readonly: boolean;
}

interface Submitter {
  email: string;
  fields: Field[];
  metadata: {
    environment: string;
    moveRequestId?: string;
  };
}

interface FormData {
  template_id: number;
  order: string;
  submitters: Submitter[];
}
const EsignatureDocument = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  if (url) {
    ReactGA.send({
      hitType: "pageview",
      page: "/EsignatureDocument/:moveRequestId",
      title: "EsignatureDocument",
    });
  }

  const thisUrl = process.env.REACT_APP_API_URL || "";
  const strikeKey = process.env.REACT_APP_STRIPE_KEY || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isConformationModal, setIsConformationModal] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  };

  const formatDate = (dateString: any) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const checkFromAddress = (moveReq: any) => {
    if (!moveReq) {
      return "---"; // Return a default value if moveReq is null or undefined
    }
    if (moveReq?.home_address !== null) {
      return moveReq.home_address;
    }
    if (moveReq?.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }
    if (moveReq?.storage !== null) {
      return moveReq.storage?.address;
    }
    if (moveReq?.combo_home_storage !== null) {
      return moveReq.combo_home_storage?.home_address.toString();
    }
    if (moveReq?.combo_apartment_storage !== null) {
      return moveReq.combo_apartment_storage?.apartment?.apt_address.toString();
    }
    return "---";
  };

  const calculateBalance = () => {
    let balance = 0;
    if (moveRequest.initial_deposit !== null) {
      balance = moveRequest.move_payment - moveRequest.initial_deposit;
    } else if (moveRequest.initial_deposit === null) {
      let commissionPercentage = 0;
      if (moveRequest.move_distance > 100) {
        commissionPercentage =
          getMoveCommissionPercentage("long-distance") || 0;
        balance =
          moveRequest.move_payment -
          Math.ceil(Number(moveRequest.price_of_items * commissionPercentage));
      } else {
        commissionPercentage = getMoveCommissionPercentage("local") || 0;

        balance =
          moveRequest.move_payment -
          Math.ceil(Number(moveRequest.price_of_items * commissionPercentage));
      }
    }
    return balance;
  };
  const splitAddressCity = (address: string, arg: string) => {
    const parts = address.split(",").map((part: string) => part.trim());
    const zip = parts[parts.length - 1];
    const country = parts.length >= 2 ? parts[parts.length - 2] : undefined;
    const state = parts.length >= 3 ? parts[parts.length - 3] : undefined;
    const city = parts.length >= 4 ? parts[parts.length - 4] : undefined;

    if (arg === "city") {
      return city;
    } else if (arg === "state") {
      return state;
    } else if (arg === "zip") {
      return zip;
    } else if (arg === "country") {
      return country;
    }
  };

  const elevatorOrStairsCharges = (move: any, climbType: string) => {
    let elevatorPayment = 0;
    let stairsPayment = 0;

    if (
      move.delivery_details?.delivery_addresses[0]?.is_elevator_accessible ===
      true
    ) {
      elevatorPayment = +75;
    } else {
      if (
        move.delivery_details?.delivery_addresses[0]?.is_elevator_accessible ===
          false &&
        move.delivery_details?.delivery_addresses[0]?.stiars_present === true
      ) {
        const floors = move.delivery_details.delivery_addresses[0].floor_no - 2;
        const stairCharges = floors * 75;
        stairsPayment += stairCharges;
      }
    }

    if (move.apartment !== null) {
      if (move.apartment.is_elevator_available === true) {
        elevatorPayment = +75;
      } else {
        if (
          move.apartment.is_elevator_accessible === false &&
          move.apartment.floor_no > 2
        ) {
          const additionalFloors = Number(move.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          stairsPayment += stairCharges;
        }
      }
    }

    if (move.storage !== null) {
      if (move.storage.is_elevator_available === true) {
        elevatorPayment = +75;
      } else {
        if (
          move.storage.is_elevator_available === false &&
          move.storage.are_stairs_present === true
        ) {
          const additionalFloors = Number(move.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          stairsPayment += stairCharges;
        }
      }
    }

    if (move.combo_apartment_storage !== null) {
      if (
        move.combo_apartment_storage.apartment.is_elevator_available === true
      ) {
        elevatorPayment = +75;
      } else {
        if (
          move.combo_apartment_storage.apartment.is_elevator_available ===
            false &&
          move.combo_apartment_storage.apartment.floor_no > 2
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          stairsPayment += stairCharges;
        }
      }

      if (move.combo_apartment_storage.storage.is_elevator_available === true) {
        elevatorPayment = +75;
      } else {
        if (
          move.combo_apartment_storage.storage.is_elevator_available ===
            false &&
          move.combo_apartment_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          stairsPayment += stairCharges;
        }
      }
    }

    if (move.combo_home_storage !== null) {
      if (move.combo_home_storage.storage.is_elevator_available === true) {
        elevatorPayment = +75;
      } else {
        if (
          move.combo_home_storage.storage.is_elevator_available === false &&
          move.combo_home_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_home_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;
          stairsPayment += stairCharges;
        }
      }
    }

    if (climbType === "elevator") {
      return elevatorPayment;
    } else {
      if (climbType === "stairs") {
        return stairsPayment;
      }
    }
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const [loadingMessage, setLoadingMessage] = useState("");
  useEffect(() => {
    setIsPageLoading(true);
    fetchMoveRequestById(moveRequestId);
  }, []);
  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("notifyContractCompletion", (requestData) => {
      setIsConformationModal(true);
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  const confirmMovePayment = async () => {
    const stripe = await loadStripe(strikeKey);
    let weMoveCommision = 0;
    let commissionPercentage = 0;
    if (moveRequest?.move_distance > 100) {
      commissionPercentage = getMoveCommissionPercentage("long-distance") || 0;
      weMoveCommision = moveRequest?.price_of_items * commissionPercentage;
    } else if (moveRequest?.move_distance <= 100) {
      commissionPercentage = getMoveCommissionPercentage("local") || 0;
      weMoveCommision = moveRequest?.price_of_items * commissionPercentage;
    }
    console.log(weMoveCommision, "----COMISSION---");
    setIsPageLoading(true);
    const valueToSend = Math.ceil(weMoveCommision) * 100;

    const body = {
      price: valueToSend,
      canonical_id: moveRequestId,
    };
    dispatch<any>(createCheckoutSession(body))
      .then(unwrapResult)
      .then((responseSession: any) => {
        stripe?.redirectToCheckout({
          sessionId: responseSession.sessionId,
        });
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };

  useEffect(() => {
    if (isConformationModal === true) {
      setIsPageLoading(true);
      // navigate(`/move-success/${moveRequest.canonical_id}`);
      if (
        moveRequest?.initial_deposit === null &&
        formData.submitters.length > 0
      ) {
        setLoadingMessage("Redirecting you to payment...");
        setTimeout(() => {
          confirmMovePayment();
        }, 3000);
      } else {
        setLoadingMessage("Contract signed successfully...");
        setTimeout(() => {
          navigate(`/move-success/${moveRequest.canonical_id}`);
        }, 3000);
      }
    }
  }, [isConformationModal, moveRequest]);
  useEffect(() => {}, [moveRequest, isConformationModal]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (
      moveRequest !== null &&
      moveRequest.contract_signed !== true &&
      count === 0
    ) {
      setCount((counter) => counter + 1);
      setLoadingMessage("Getting the document ready...");
      createESignatureDocument();
    }
    if (moveRequest === null && count === 0) {
      setLoadingMessage("Please wait...");
    }
    if (
      moveRequest !== null &&
      moveRequest.contract_signed === true &&
      moveRequest.initial_deposit !== null &&
      count === 0
    ) {
      setCount((counter) => counter + 1);
      setLoadingMessage(
        "You have already signed the document. Redirecting you..."
      );
      setTimeout(() => {
        navigate(`/move-success/${moveRequest.canonical_id}`);
      }, 4000);
    }
    if (
      moveRequest !== null &&
      moveRequest.contract_signed === true &&
      moveRequest.initial_deposit === null &&
      count === 0
    ) {
      setCount((counter) => counter + 1);
      setLoadingMessage(
        "You have already signed the document. Redirecting you to payment..."
      );
      setTimeout(() => {
        confirmMovePayment();
      }, 4000);
    }
  }, [moveRequest]);

  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    template_id: 446956,
    order: "preserved",
    submitters: [],
  });

  useEffect(() => {
    if (formData.submitters.length !== 0) {
      fetchSignAndSubmit(formData);
    }
  }, [formData.submitters.length]);
  const createESignatureDocument = () => {
    const updatedFormData = {
      template_id: 446956,
      order: "preserved",
      bcc_completed: getCarrierEmail(),
      submitters: [
        {
          email: moveRequest.user.email,
          fields: [
            {
              name: "companyTitle",
              default_value: getCarrierTitle() || "",
              readonly: true,
            },
            {
              name: "companyName",
              default_value: getCarrierTitle() || "",
              readonly: true,
            },
            {
              name: "companyPhone",
              default_value: callSupportContact() || "",
              readonly: true,
            },
            {
              name: "companyAddress",
              default_value: "254 Chapman Road, Suite 209",
              readonly: true,
            },
            { name: "companyZip", default_value: "88888", readonly: true },
            { name: "companyCity", default_value: "Newark", readonly: true },
            { name: "companyState", default_value: "DE", readonly: true },
            {
              name: "customerName",
              default_value:
                moveRequest?.user?.first_name +
                " " +
                moveRequest?.user?.last_name,
              readonly: true,
            },
            {
              name: "customerPhone",
              default_value: moveRequest?.user?.phone_number,
              readonly: true,
            },
            {
              name: "destinationAddress",
              default_value:
                moveRequest?.delivery_details?.delivery_addresses[0]
                  ?.complete_address,
              readonly: true,
            },
            {
              name: "pickupCityState",
              default_value:
                splitAddressCity(checkFromAddress(moveRequest), "city") +
                ", " +
                splitAddressCity(checkFromAddress(moveRequest), "state"),
              readonly: true,
            },
            {
              name: "destinationCityState",
              default_value:
                splitAddressCity(
                  moveRequest.delivery_details?.delivery_addresses[0]
                    .complete_address,
                  "city"
                ) +
                ", " +
                splitAddressCity(
                  moveRequest.delivery_details?.delivery_addresses[0]
                    .complete_address,
                  "state"
                ),
              readonly: true,
            },
            {
              name: "pickupZip",
              default_value: splitAddressCity(
                checkFromAddress(moveRequest),
                "zip"
              ),
              readonly: true,
            },
            {
              name: "destinationZip",
              default_value: splitAddressCity(
                moveRequest.delivery_details.delivery_addresses[0]
                  .complete_address,
                "zip"
              ),
              readonly: true,
            },
            {
              name: "moveDate",
              default_value:
                formatDate(moveRequest?.pickup_date_from) +
                " to " +
                formatDate(moveRequest?.pickup_date_to),
              readonly: true,
            },
            {
              name: "elevatorFee",
              default_value: `${
                elevatorOrStairsCharges(moveRequest, "elevator") !== 0
                  ? "$ " + elevatorOrStairsCharges(moveRequest, "elevator")
                  : "-"
              }`,
              readonly: true,
            },
            {
              name: "paymentType",
              default_value: "Stripe Checkout",
              readonly: true,
            },
            {
              name: "excessStairsFee",
              default_value: `${
                elevatorOrStairsCharges(moveRequest, "stairs") !== 0
                  ? "$ " + elevatorOrStairsCharges(moveRequest, "stairs")
                  : "-"
              }`,

              readonly: true,
            },
            {
              name: "form1Title",
              default_value: "BILL OF LADING",
              readonly: true,
            },
            {
              name: "pickupAddress",
              default_value: checkFromAddress(moveRequest),
              readonly: true,
            },
            {
              name: "totalEstimatedCost",
              default_value: `$ ${moveRequest?.move_payment}` || "-",
              readonly: true,
            },
            { name: "longCarryFee", default_value: "-", readonly: true },
            {
              name: "packingAndMaterials",
              default_value: `${
                moveRequest?.delivery_details?.packaging?.packaging_payment
                  ? "$ " +
                    moveRequest?.delivery_details?.packaging?.packaging_payment
                  : "-"
              }`,
              readonly: true,
            },
            {
              name: "customerEmail",
              default_value: moveRequest?.user?.email,
              readonly: true,
            },
            {
              name: "jobReferenceNo",
              default_value: moveRequest?.move_order_number,
              readonly: true,
            },
            {
              name: "FADP",
              default_value: `${formatDate(moveRequest?.pickup_date_from)} - ${formatDate(
                moveRequest?.pickup_date_to
              )}`,
              readonly: true,
            },
            {
              name: "estimatedCost",
              default_value: `$ ${moveRequest?.move_payment}` || "-",
              readonly: true,
            },
            {
              name: "customerSignDate",
              default_value: getDate(),
              readonly: true,
            },
            {
              name: "shuttleFee",
              default_value:
                moveRequest.delivery_details.shuttle_required === "YES"
                  ? "Yes"
                  : moveRequest.delivery_details.shuttle_required === "NO"
                    ? "No"
                    : "Unsure",

              readonly: true,
            },
            {
              name: "balanceOfMove",
              default_value: `$ ${calculateBalance()}` || "-",
              readonly: true,
            },
            {
              name: "numberOfBoxes",
              default_value: moveRequest?.no_of_boxes_for_boxable_items || "-",
              readonly: true,
            },
            {
              name: "form2Title",
              default_value: "MOVE ESTIMATE",
              readonly: true,
            },
            {
              name: "inventoryListCaptured",
              default_value:
                moveRequest.roomDetails
                  ?.map((room: any) => {
                    const imageItems = room.room_images
                      ?.map((image: any) => {
                        const items = image.items
                          ?.map((item: any) => item.item_name)
                          .join(", ");
                        return items;
                      })
                      .join(", ");
                    return `${room.title.toString()}: ${imageItems}`;
                  })
                  .join("\n") || "-",
              readonly: true,
            },
          ],
          metadata: {
            environment:
              process.env.REACT_APP_API_URL === "http://localhost:5000/"
                ? "staging"
                : process.env.REACT_APP_URL === "https://staging.wemoveai.com"
                  ? "staging"
                  : process.env.REACT_APP_URL ===
                      "https://production.wemoveai.com"
                    ? "prod"
                    : process.env.REACT_APP_URL ===
                        "https://turbotenant.wemoveai.com"
                      ? "turbotenant"
                      : process.env.REACT_APP_URL ===
                          "https://sold.wemoveai.com"
                        ? "sold"
                        : process.env.REACT_APP_URL ===
                            "https://sold.wemoveai.com"
                          ? "sold"
                          : process.env.REACT_APP_URL ===
                              "https://rockstarsprocomplete.wemoveai.com"
                            ? "rockstarsprocomplete"
                            : process.env.REACT_APP_URL ===
                                "https://elevatedliving.wemoveai.com"
                              ? "elevatedliving"
                              : process.env.REACT_APP_URL ===
                                  "https://harrington-movers.wemoveai.com"
                                ? "harringtonmoversfull"
                                : process.env.REACT_APP_URL ===
                                    "https://dependable-movers.wemoveai.com"
                                  ? "dependable-movers"
                                  : " ",
            moveRequestId: moveRequestId,
          },
        },
      ],
    };
    setFormData(updatedFormData);
  };
  useEffect(() => {
    if (moveRequest) {
      createESignatureDocument();
    }
  }, [moveRequest, moveRequestId]);

  const fetchSignAndSubmit = async (formData: any) => {
    try {
      const response = await dispatch<any>(submitEsignatureForm(formData)).then(
        unwrapResult
      );
      setLoadingMessage("");
      setIsPageLoading(false);
      const slug = response[0]?.slug;
      if (slug) {
        const url = `https://docuseal.com/d/${slug}`;
        setIframeSrc(url);
      } else {
        console.error("Slug not found in the response.");
      }
    } catch (err: any) {
      console.error("Form Submission Error------", err);
      setErrorMessage(`Failed to submit. ${err}`);
      setErrorDialogOpen(true);
    }
  };
  return (
    <>
      <Grid container mt={2} mb={2} p={1}>
        <Grid item xs={12} mb={2} display="flex" justifyContent={"center"}>
          {errorDialogOpen && (
            <Dialog
              open={errorDialogOpen}
              onClose={() => setErrorDialogOpen(false)}
              aria-labelledby="error-dialog-title"
              aria-describedby="error-dialog-description"
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText id="error-dialog-description">
                  {errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setErrorDialogOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {iframeSrc && <DocusealForm src={iframeSrc} />}

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <Box sx={{ width: "50%" }}>
                <LinearProgress />
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  textAlign="center"
                  mt={1}
                >
                  {loadingMessage}
                </Typography>
              </Box>
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default EsignatureDocument;
