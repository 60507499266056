import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Container,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  Modal,
  Tooltip,
} from "@mui/material";
import jsPDF from "jspdf";
import { fetchInventoryByPhone } from "../../redux/actions/room-details";

const InventoryPopulate = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [inventoryData, setInventoryData] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [phoneData, setPhoneData] = useState("");

  const thisUrl = process.env.REACT_APP_API_URL || "";
  const theme = useTheme();
  const siteUrl = process.env.REACT_APP_URL || "";
  useEffect(() => {
    const socket = io(thisUrl);
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });
    socket.on("showInventoryResponse", (phoneResponse: any) => {
      setPhoneData(phoneResponse);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchInventoryData = (phone: any) => {
    const body = { phone };
    dispatch<any>(fetchInventoryByPhone(body))
      .then(unwrapResult)
      .then((res: any) => {
        setInventoryData(res);
      })
      .catch((err: any) => {
        console.error("Error fetching inventory:", err);
      });
  };

  useEffect(() => {
    if (phoneData && phoneData === phoneNumber) {
      fetchInventoryData(phoneData);
    }
  }, [phoneData]);

  const handleGenerateUrl = () => {
    if (phoneNumber.trim() === "") {
      setError("Please enter a valid phone number");
      setModalOpen(true);
      return;
    }
    if (phoneNumber.length < 10) {
      setError("Phone number must be 10 digits");
      setModalOpen(true);
      return;
    }
    setGeneratedUrl(`${siteUrl}/add-inventory/${phoneNumber}`);
  };

  const handleInputPhoneNumber = (event: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 10;

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    setPhoneNumber(inputValue);
    setError(inputValue.length < 10 ? "Phone number must be 10 digits" : "");

    if (inputValue === "") {
      setGeneratedUrl("");
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(generatedUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const getVolume = (item: any) => {
    const dimensions = item.predicted_dimensions || [];
    const length = dimensions[0] ? parseFloat(dimensions[0]) : 0;
    const width = dimensions[1] ? parseFloat(dimensions[1]) : 0;
    const height = dimensions[2] ? parseFloat(dimensions[2]) : 0;
    const itemVolume = length * width * height;
    const totalCountVolume = itemVolume * item.count;
    return totalCountVolume;
  };

  const getWeightInKg = (item: any) => {
    const volume = getVolume(item);
    return volume * 7 * 0.453592;
  };

  const getWeightInPounds = (item: any) => {
    const volume = getVolume(item);
    return volume * 7;
  };

  const calculateTotals = () => {
    let totalVolume = 0;
    let totalWeightKg = 0;
    let totalWeightPounds = 0;

    inventoryData.forEach((item) => {
      const itemVolume = getVolume(item);
      const itemWeightKg = getWeightInKg(item) * item.count;
      const itemWeightPounds = getWeightInPounds(item) * item.count;

      totalVolume += itemVolume;
      totalWeightKg += itemWeightKg;
      totalWeightPounds += itemWeightPounds;
    });

    return { totalVolume, totalWeightKg, totalWeightPounds };
  };

  const { totalVolume, totalWeightKg, totalWeightPounds } = calculateTotals();

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    doc.text("Inventory Report", 10, 10);

    let yPosition = 20;
    const pageHeight = doc.internal.pageSize.height;

    inventoryData.forEach((item: any, index: number) => {
      if (yPosition > pageHeight - 30) {
        doc.addPage();
        yPosition = 20;
      }

      doc.text(`Item ${index + 1}:`, 10, yPosition);
      yPosition += 10;
      doc.text(`Object Name: ${item.object_name}`, 10, yPosition);
      yPosition += 10;
      doc.text(`Count: ${item.count}`, 10, yPosition);
      yPosition += 10;
      doc.text(`Bulky: ${item.bulky ? "Yes" : "No"}`, 10, yPosition);
      yPosition += 10;
      doc.text(
        `Dimensions: ${item.predicted_dimensions?.join(" x ") || "N/A"}`,
        10,
        yPosition
      );
      yPosition += 15;
    });

    if (yPosition > pageHeight - 30) {
      doc.addPage();
      yPosition = 20;
    }

    doc.text("Calculated Volume and Weights:", 10, yPosition);
    yPosition += 10;
    doc.text(
      `Total Volume (Cubic Feet): ${totalVolume.toFixed(3)}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(
      `Total Weight (Pounds): ${totalWeightPounds.toFixed(3)}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(
      `Total Weight (Kilograms): ${totalWeightKg.toFixed(3)}`,
      10,
      yPosition
    );

    doc.save("inventory.pdf");
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Inventory Populate
      </Typography>

      <Box mt={2}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Enter Phone Number:
        </Typography>
        <TextField
          value={phoneNumber}
          onChange={handleInputPhoneNumber}
          placeholder="Enter phone number"
          fullWidth
          variant="outlined"
          error={!!error}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+1</InputAdornment>
            ),
          }}
        />
      </Box>

      <Box mt={3}>
        <Button
          onClick={handleGenerateUrl}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ height: 45, borderRadius: 2 }}
        >
          Generate URL
        </Button>
      </Box>

      {generatedUrl && (
        <Box mt={4}>
          <Typography variant="subtitle1">Generated URL:</Typography>
          <Typography
            variant="body1"
            sx={{
              wordBreak: "break-word",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {generatedUrl}
          </Typography>
          <Tooltip
            title={copied ? "URL copied" : ""}
            open={copied}
            disableInteractive
            arrow
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCopyUrl}
              sx={{ ml: 2, mt: 2 }}
            >
              Copy URL
            </Button>
          </Tooltip>
        </Box>
      )}

      <Box mt={5}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          Inventory Data
        </Typography>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                  Object Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                  Count
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                  Bulky
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                  Dimensions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryData.length > 0 ? (
                inventoryData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.object_name}</TableCell>
                    <TableCell>{item.count}</TableCell>
                    <TableCell>{item.bulky ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      {item.predicted_dimensions?.join(" x ") || "N/A"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No inventory available yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Total Inventory Data
          </Typography>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Total Volume (Cubic Feet)
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Total Weight (Pounds)
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Total Weight (Kilograms)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{totalVolume.toFixed(3)}</TableCell>
                  <TableCell>{totalWeightPounds.toFixed(3)}</TableCell>
                  <TableCell>{totalWeightKg.toFixed(3)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom color="error">
            {error}
          </Typography>
          <Button
            onClick={() => setModalOpen(false)}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </Box>
      </Modal>

      <Box mt={4}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadPDF}
          sx={{
            backgroundColor: theme.palette.primary.main,
            mb: 3,
            display: "block",
            marginLeft: "auto",
          }}
        >
          Download PDF Report
        </Button>
      </Box>
    </Container>
  );
};

export default InventoryPopulate;
